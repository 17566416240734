// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-canopy-index-js": () => import("./../../../src/pages/canopy/index.js" /* webpackChunkName: "component---src-pages-canopy-index-js" */),
  "component---src-pages-extension-index-js": () => import("./../../../src/pages/extension/index.js" /* webpackChunkName: "component---src-pages-extension-index-js" */),
  "component---src-pages-hong-x-panasonic-index-js": () => import("./../../../src/pages/hong-x-panasonic/index.js" /* webpackChunkName: "component---src-pages-hong-x-panasonic-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-the-question-index-js": () => import("./../../../src/pages/the-question/index.js" /* webpackChunkName: "component---src-pages-the-question-index-js" */),
  "component---src-pages-tung-chung-prize-index-js": () => import("./../../../src/pages/tung-chung-prize/index.js" /* webpackChunkName: "component---src-pages-tung-chung-prize-index-js" */),
  "component---src-templates-about-page-template-review-js": () => import("./../../../src/templates/aboutPageTemplateReview.js" /* webpackChunkName: "component---src-templates-about-page-template-review-js" */),
  "component---src-templates-artwork-list-c-template-js": () => import("./../../../src/templates/artworkListCTemplate.js" /* webpackChunkName: "component---src-templates-artwork-list-c-template-js" */),
  "component---src-templates-artwork-list-h-template-js": () => import("./../../../src/templates/artworkListHTemplate.js" /* webpackChunkName: "component---src-templates-artwork-list-h-template-js" */),
  "component---src-templates-press-image-template-js": () => import("./../../../src/templates/pressImageTemplate.js" /* webpackChunkName: "component---src-templates-press-image-template-js" */),
  "component---src-templates-project-e-template-js": () => import("./../../../src/templates/projectETemplate.js" /* webpackChunkName: "component---src-templates-project-e-template-js" */),
  "component---src-templates-project-e-template-review-js": () => import("./../../../src/templates/projectETemplateReview.js" /* webpackChunkName: "component---src-templates-project-e-template-review-js" */),
  "component---src-templates-project-list-e-template-js": () => import("./../../../src/templates/projectListETemplate.js" /* webpackChunkName: "component---src-templates-project-list-e-template-js" */),
  "component---src-templates-project-list-q-template-js": () => import("./../../../src/templates/projectListQTemplate.js" /* webpackChunkName: "component---src-templates-project-list-q-template-js" */),
  "component---src-templates-project-list-t-template-js": () => import("./../../../src/templates/projectListTTemplate.js" /* webpackChunkName: "component---src-templates-project-list-t-template-js" */),
  "component---src-templates-project-q-template-js": () => import("./../../../src/templates/projectQTemplate.js" /* webpackChunkName: "component---src-templates-project-q-template-js" */),
  "component---src-templates-project-q-template-review-js": () => import("./../../../src/templates/projectQTemplateReview.js" /* webpackChunkName: "component---src-templates-project-q-template-review-js" */),
  "component---src-templates-project-t-template-js": () => import("./../../../src/templates/projectTTemplate.js" /* webpackChunkName: "component---src-templates-project-t-template-js" */),
  "component---src-templates-project-t-template-review-js": () => import("./../../../src/templates/projectTTemplateReview.js" /* webpackChunkName: "component---src-templates-project-t-template-review-js" */)
}

